import './App.css';
import { useEffect, useState } from 'react'
import Home from './Pages/Home.js'
import Login from './Pages/Login.js'
import * as auth from './api/auth.js';


function App() {
  const [isLoggedIn, toggleLoginState] = useState(false);
  const [isDataReady, toggleDataReady] = useState(false);
  const [isLoginError, toggleLoginError] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    (async function () {
      const lsUser = auth.getUser();
      if (!lsUser?.name || !lsUser?.identifier) return auth.clearLocalStorage();
      setUser(lsUser);
      toggleLoginState(true);
      mockLoading();
    }());
  }, []);

  function resetLoginError() {
    toggleLoginError(false)
  }

  function mockLoading() {
    setTimeout(() => { // FOR LOGIN page loading
      toggleDataReady(true);
    }, 2000);
  }

  async function login({ email, password }) {
    try {
      const user = await auth.login({ email, password });
      toggleLoginState(true);
      toggleLoginError(false);
      setUser(user);
      mockLoading();
    } catch(err) {
      toggleLoginState(false);
      toggleLoginError(true);
    }
  }

  async function logout() {
    await auth.logout();
    toggleLoginState(false);
    toggleDataReady(false);
  }
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100vw', minHeight: '100vh', backgroundColor: '#ebebeb' }}>
      {isLoggedIn && isDataReady ? <Home user={user} logout={logout}/> 
      : <Login
          login={login}
          user={user}
          isLoginError={isLoginError}
          isLoggedIn={isLoggedIn}
          resetLoginError={resetLoginError}
        />}
    </div>
  );
}

export default App;
