const { REACT_APP_PW_MANTYH } = process.env;

const users = {
  'aah@kelahealth.com': {
    password: 'passwordPassword',
    name: 'Illinois Masonic Medical Center',
    identifier: 'HOSPITAL_2'
  },
};

export async function login({ email, password }) {
  const user = users[email];
  if (!user || user.password !== password) throw Error('Invalid Login');
  localStorage.setItem("email", user.email);
  localStorage.setItem("name", user.name);
  localStorage.setItem("identifier", user.identifier);
  return {
    email,
    name: user.name,
    identifier: user.identifier
  }
}

export function getUser() {
  return {
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
    identifier: localStorage.getItem('identifier')
  }
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function logout() {
  clearLocalStorage();
}