import { useEffect, useState } from 'react';
import logo from '../assets/logo-white.png';
import { DotLoader } from 'react-spinners';

export default function ({ login, user, isLoggedIn, isLoginError }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSetEmail(em) {
    setEmail(em);
  }
  function handleSetPassword(pw) {
    setPassword(pw);
  }

  async function handleLogin() {
    await login({ email, password });
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={{ padding: '20px' }}>
          <img src={logo} style={{ height: 'auto', width: '80%' }} alt="Logo" />
          <div style={{ color: 'white', fontWeight: 500, fontStyle: 'italic', fontSize: '20px', }}>Reporting Portal</div>
        </div>
        {!isLoggedIn ? <InputArea 
          email={email} 
          password={password}
          isLoginError={isLoginError}
          handleLogin={handleLogin}
          handleSetEmail={handleSetEmail}
          handleSetPassword={handleSetPassword}
        />
        : <DataLoading user={user}/>}
      </div> {/* End Card */}
    </div>
  );
}


function InputArea({ isLoginError, email, password, handleLogin, handleSetEmail, handleSetPassword }) {
  useEffect(() => {
    handleSetEmail('');
    handleSetPassword('');
  }, [isLoginError])
  return (
    <div style={{ height: '80%', backgroundColor: 'white', display: 'flex', flexDirection: 'column', padding: '20px', fontWeight: 700, borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}>
      Sign in with your email and password
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <label style={styles.inputLable} htmlFor="email">Email</label>
          <div style={{ marginRight: '20px', color: 'red', opacity: isLoginError ? 1 : 0 }}>Invalid Login</div>
        </div>
        <input 
          onChange={({ target }) => handleSetEmail(target.value)} 
          value={email}
          style={styles.input} 
          type="text" placeholder="name@org.com" name="email" id="email"
        ></input>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
        <label style={styles.inputLable} htmlFor="password">Password</label>
        <input 
          onChange={({ target }) => handleSetPassword(target.value)} 
          value={password}
          style={styles.input} 
          type="password" placeholder="password" name="password" id="password"
        ></input>
      </div>
      <button onClick={handleLogin} style={{ ...styles.button, marginTop: '35px' }}><span>Sign in</span></button>
    </div> 
  )
}

function DataLoading({user}) {
  return (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontWeight: 700, color: 'white', paddingBottom: '50px'}}>
      <div style={{ marginBottom: '10px', fontSize: '32px'}}>Welcome!</div>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{ fontSize: '24px', color: '#fcc414', marginBottom: '30px' }}>Generating your report</div>
        <DotLoader color="#fcc414" />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center' 
  },
  topBlueMargin: { 
    display: 'flex', 
    flexDirection: 'column', 
    height: '100%', 
    borderRadius: '8px', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  card: { 
    display: 'flex',
    flexDirection: 'column', 
    // backgroundColor: '#0367B4',
    background: 'linear-gradient(90deg, rgba(4,100,180,1) 3%, rgba(16,140,235,1) 80%, rgba(36,156,244,1) 100%)', 
    width: '400px', 
    height: '500px',
    boxShadow: '5px 5px 5px grey',
    borderRadius: '8px'
  },
  input: {
    width: '95%',
    height:'5vh',
    fontSize: '16px',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    color: '#6C757D',
    textIndent: '1.5vh'
  },
  inputLable: {
    display: 'block',
    color: '#333333',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    paddingTop: '.5rem',
    paddingBottom: '.5rem'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0367B4',
    color: 'white',
    fontSize: '20px',
    fontWeight: 700,
    padding: '10px 60px',
    borderRadius: '5px',
    margin: '10px 0px',
    cursor: 'pointer'
  }
}